import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import moment from 'moment'

import * as styles from './header.module.scss'
import robertyLogo from '../../assets/robertyAutomation.svg'
import robertyLogoWhite from '../../assets/robertyAutomation-white.svg'

import { headerMenuItems } from '../../utils/commonLinks'
import { useMediaQuery } from '../../utils/hooks'

import { Button } from '../button'
import { MobileMenu } from './mobileMenu'
import { SalesBanner } from '../salesBanner'
import i18n from '../../i18n'

interface HeaderProps {
  isWhite?: boolean
  isHome?: boolean
}

export const Header: React.FC<HeaderProps> = ({ isWhite, isHome }) => {
  const [sticky, setSticky] = useState(false)
  const isMobile = useMediaQuery(`768`)
  const isInSale = !!process.env.GATSBY_IS_ON_SALE

  const onScroll = () => {
    setSticky(window.scrollY > 25)
  }

  useEffect(() => {
    window.addEventListener(`scroll`, onScroll)
    return () => {
      window.removeEventListener(`scroll`, onScroll)
    }
  }, [])

  const renderMenuItem = (
    sameOrigin: boolean,
    index: number,
    href: string,
    text: string
  ) => {
    return sameOrigin ? (
      <AnchorLink
        key={index}
        className={clsx([styles.menuItem, `d-none`, `d-md-inline-block`])}
        to={href}
      >
        {text}
      </AnchorLink>
    ) : (
      <a
        key={index}
        className={clsx([styles.menuItem, `d-none`, `d-lg-inline-block`])}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    )
  }

  return (
    <header
      className={clsx({
        [styles.header]: true,
        [styles.headerSticky]: sticky,
        [styles.whiteMenu]: isWhite,
        [styles.hasSales]: isInSale,
      })}
    >
      {isInSale && <SalesBanner hasBackground={isWhite} />}

      <div className="container-fluid">
        <div className={clsx([`row`])}>
          <div className="col-12">
            <nav className={styles.mainMenu}>
              <div
                className="d-flex align-items-center"
                style={{ zIndex: 999 }}
              >
                <AnchorLink to="/" className="d-flex align-items-center">
                  {!sticky ? (
                    <img
                      className={clsx(styles.logo, 'mr-3')}
                      style={{ width: '10rem' }}
                      src={!isWhite ? robertyLogo : robertyLogoWhite}
                      alt="Roberty"
                    />
                  ) : (
                    <img
                      className={clsx(styles.logo, 'mr-3')}
                      style={{ width: '10rem' }}
                      src={robertyLogo}
                      alt="Roberty"
                    />
                  )}
                </AnchorLink>
                {headerMenuItems
                  .filter((item) => item.position === 'left')
                  .map((item, index) => {
                    if (!isHome && item.href === '/contactSales') return
                    return renderMenuItem(
                      item.sameOrigin,
                      index,
                      item.href,
                      item.text
                    )
                  })}
              </div>
              {isHome && (
                <div className="d-flex align-items-center">
                  {headerMenuItems
                    .filter((item) => item.position === 'right')
                    .map((item, index) =>
                      renderMenuItem(
                        item.sameOrigin,
                        index,
                        item.href,
                        item.text
                      )
                    )}
                  {!isInSale && (
                    <AnchorLink to={`/contactSales`}>
                      <Button
                        color={'primary'}
                        className={clsx('ml-3 d-none d-md-inline-block')}
                      >
                        {i18n[`common.startNow`]}
                      </Button>
                    </AnchorLink>
                  )}
                </div>
              )}
            </nav>
          </div>
        </div>
      </div>
      {isMobile && (
        <MobileMenu isSticky={sticky}>
          {headerMenuItems.map((item, index) =>
            renderMenuItem(item.sameOrigin, index, item.href, item.text)
          )}
          <AnchorLink to={`/contactSales`}>
            <Button
              color={'primary'}
              className="d-lg-inline-block"
              size="large"
            >
              {i18n[`common.startNow`]}
            </Button>
          </AnchorLink>
        </MobileMenu>
      )}
    </header>
  )
}
