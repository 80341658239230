import I18nMessages from "../messages"

const ptBR: I18nMessages = {
  "common.50OFF": `e aproveite o desconto de 50% ​😄 *`,
  "common.above": `Acima de`,
  "common.actionExclusive": `Demandas Exclusivas`,
  "common.allRight": `Tudo certo! Em breve nossa equipe vai ler a sua mensagem 🥳`,
  "common.automatizationRoutine": `Se você está pensando em automatizar uma rotina que possui regras de negócio complexas, nosso designer torna isso fácil!`,
  "common.back": `Voltar`,
  "common.backHome": `Clique aqui para voltar à página inicial`,
  "common.backToyou": `Te retornamos o mais breve possível`,
  "common.bannerPartner": `Banner Treinamento Roberty Partners Outubro/2022`,
  "common.become": `Torne-se um`,
  "common.beImplanter": `Faça parte do nosso grupo de implantadores de RPA utilizando o Roberty como ferramenta.`,
  "common.beInvestor": `Seja um Investidor`,
  "common.bePartner": `Seja um Sócio Investidor do Roberty 😃`,
  "common.beRoberty": `Seja um Roberty`,
  "common.beRobertyPartner": `Virar parceiro`,
  "common.benefit": `é um benefício garantido!`,
  "common.benefitPartner": `Benefícios para parceiros`,
  "common.business": `Empresarial`,
  "common.businessModel": `Nosso modelo de negócios`,
  "common.businessName": `Nome da empresa`,
  "common.businessRisk": `Suporte técnico`,
  "common.businessRiskText": `Conte com um time de tecnologia dedicado para a resolução de seus problemas.`,
  "common.businessRoberty": `Conheça algumas empresas que já tiveram seus processos automatizados com robôs criados no Roberty.`,
  "common.businessSize": `Tamanho da Empresa`,
  "common.buyRoberty": `para adquirir o Roberty`,
  "common.callToYou": `Nós ligamos pra você`,
  "common.captcha": `Resolução de Captcha`,
  "common.carrying": `Carregando...`,
  "common.createLogic": `Crie lógicas mais`,
  "common.about": `Sobre o Roberty Automation`,
  "common.aboutRoberty": `Somos uma plataforma de `,
  "common.RPA": `automação robótica de processos (RPA) `,
  "common.robotsIntelligent": `que permite a construção de robôs inteligentes para hiperautomação de diversas tarefas, como emissão de notas fiscais, leitura e manipulação de planilhas, criação de pedidos de compra, cadastro de leads em ferramentas de CRM, dentre muitas outras de forma `,
  "common.intuitive": `intuitiva e sem uso de código.`,
  "common.AgilSimple": `Com a interface mais simples, ágil e intuitiva do mercado, a `,
  "common.robertyToolRPA": `ferramenta de RPA Roberty `,
  "common.LowCode": `é totalmente low-code, `,
  "common.useTool": `permitindo que qualquer pessoa possa utilizá-la com facilidade.`,
  "common.creationRobot": `Criaçāo do robô`,
  "common.checkout": `Confira abaixo os custos para automatizar um processo com o Roberty e entre em contato com nossa equipe de vendas!`,
  "common.chooseDate": `Escolha a melhor data para você`,
  "common.choosePlan": `Escolher Plano`,
  "common.complexy": `complexas`,
  "common.compliance": `Capacitações e treinamentos`,
  "common.complianceText": `Acesso a diversos treinamentos e capacitação da sua equipe em 30 dias.`,
  "common.contact": `Contate nossa`,
  "common.contactNow": `Entre em contato e comece agora mesmo!`,
  "common.contacts": `Contatos`,
  "common.contactUs": `Fale conosco`,
  "common.copyright": `Copyright © ${new Date().getFullYear()} Roberty Automation. Todos os direitos reservados.`,
  "common.corporate": `Corporativo`,
  "common.costBenefit": `O Melhor Custo X Benefício das Plataformas de RPA!`,
  "common.costReduction": `Melhor custo benefício`,
  "common.costReductionText": `Economize ao utilizar a solução com o melhor custo benefício do mercado.`,
  "common.days": `Dias`,
  "common.delivery": `Entrega do robô em ambiente de produção`,
  "common.demonstration": `Demonstração Designer`,
  "common.demonstrationTest": `Agendar uma demonstração`,
  "common.designerSoftware": `Software de Designer`,
  "common.development": `Desenvolvimento da automação por especialistas em RPA`,
  "common.device": `Dispositivo`,
  "common.devices": `Dispositivos`,
  "common.docs": `Documentação`,
  "common.docsCompleted": `Documentação completa`,
  "common.emailSending": `Envios de E-mail`,
  "common.employees": `de serviços de TI `,
  "common.robertyHeader": `com o Roberty`,
  "common.endPromotion": `Para o fim da promoção!`,
  "common.errorTryAgain": `Ops, algo deu errado 😢. Por favor tente novamente daqui a pouco.`,
  "common.experienceIn": `Experiência em`,
  "common.FAQ": `FAQ e Dúvidas frequentes`,
  "common.features": `Features disponíveis`,
  "common.feedback": `Você tem um feedback ou uma dúvida sobre o Roberty?`,
  "common.fillFields": `Preencha os campos necessários que a nossa equipe entrará em contato os mais rapido possível.`,
  "common.fillForm": `Preencha os campos do formulário para enviar uma mensagem pra gente.`,
  "common.from": `A partir de`,
  "common.fullName": `Seu nome completo`,
  "common.generalQuestions": `Para perguntas gerais, visite nossa página de`,
  "common.getAll": `Obtenha tudo que o Roberty Automation tem a oferecer para o seu negócio!`,
  "common.getTouch": `Entrar em contato`,
  "common.great": `Ótimo`,
  "common.goToPlans": `Ir para os planos`,
  "common.half": `METADE`,
  "common.Hours": `Horas`,
  "common.hours": `horas`,
  "common.hour": `hora`,
  "common.institutional": `Institucional`,
  "common.interested": `Como podemos ajudar você?`,
  "common.investor": `Investidor`,
  "common.investiment": `Investimento`,
  "common.knowPlans": `Pronto para levar seus serviços de TI para o próximo nível?`,
  "common.letsTalk": `Vamos conversar`,
  "common.licensing": `Licenciamento do Roberty em 1 PC`,
  "common.limitedTime": `Oferta válida por tempo limitado, aproveite agora essa oportunidade única!`,
  "common.linkedDevices": `Vínculo de dispositivos`,
  "common.loading": `Estamos carregando...`,
  "common.logIn": `Entrar`,
  "common.maintenance": `Manutenção, alterações e customizações solicitadas pelo seu cliente`,
  "common.makesDifference": `faz a diferença`,
  "common.messageText": `Sua mensagem`,
  "common.minutes": `Min`,
  "common.monitoring": `Monitoramento 24 horas por dia`,
  "common.monitoringDescription": `Monitoramento 24/7`,
  "common.monitoringStatus": `Monitoramento do status do robô`,
  "common.monitoringText": `O Roberty Automation conta com uma equipe de suporte altamente especializada, que monitora os robôs de forma a mantê-los em funcionamento 24 horas por dia, 7 dias por semana.`,
  "common.monitoring24/7": `Monitoramento`,
  "common.month": `mês`,
  "common.moreFunctions": `Recursos adicionais`,
  "common.newActions": `Ações Customizadas`,
  "common.newsLetter": `Newsletter`,
  "common.newsLetterError": `Ops, algo deu errado. Por favor tente novamente daqui a pouco.`,
  "common.newsLetterRegistered": `Ops, aparentemente você já está registrado na nossa newsletter `,
  "common.newsLetterRoberty": `Fique por dentro de todas as novidades do Roberty!`,
  "common.newsLetterSubscribe": `Assine nossa`,
  "common.newsLetterSuccess": `Obrigado pelo cadastro! Em breve você estará recebendo as novidades do Roberty`,
  "common.newUsers": `* - Válido apenas para novos usuários da plataforma.`,
  "common.noFeature": `Nenhuma feature adicionada...`,
  "common.notFound": `Não conseguimos encontrar a página que você solicitou.`,
  "common.OCR": `OCR Inteligente`,
  "common.onlineTraining": `Treinamento Online`,
  "common.onlineTrainingDescription": `Aprenda a usar o Roberty Designer`,
  "common.openWpp": `Contate-nos WhatsApp!`,
  "common.optionsTalk": ` Confira abaixo algumas opções para falar com a gente.`,
  "common.pageNotFound": `404 - Página nāo encontrada`,
  "common.partner": `Torne-se parceiro`,
  "common.partnerRoberty": `Roberty Partner`,
  "common.performance": `Alta velocidade de desenvolvimento`,
  "common.performanceText": `A natureza simples do Roberty propicia alta agilidade no processo de desenvolvimento de robôs.`,
  "common.phone": `Seu melhor telefone/celular`,
  "common.plans": `planos`,
  "common.Plans": `Planos`,
  "common.prefer": `Se preferir,`,
  "common.price": `do preço 🤑`,
  "common.prices": `preços`,
  "common.pricesRoberty": `Preços | Roberty Automation`,
  "common.privacyPolicy": `política de privacidade.`,
  "common.PrivacyPolicy": `Política de Privacidade`,
  "common.processSurvey": `levantamento de processos`,
  "common.popular": `Popular`,
  "common.quality": `Potencializamos seu negócio`,
  "common.qualityText": `Envio de leads de potenciais clientes e auxílio nas conduções comerciais.`,
  "common.recipe": `100% da receita é SUA!`,
  "common.recipeCliente": `Receitas obtidas por cada cliente`,
  "common.recording": `Gravação da rotina utilizando o Roberty Screen Capture`,
  "common.recurringRevenue": `Receita Recorrente`,
  "common.referralsLeads": `Indicações de Leads Potenciais`,
  "common.release": `Expanda seu portfólio`,
  "common.repeating": `repetitivo`,
  "common.requests": `Requisições`,
  "common.returnCalls": `Nós retornamos às ligações de segunda à sexta-feira das 9h às 17h.`,
  "common.robertyAlly": `Roberty Designer, o seu maior aliado`,
  "common.robertyCapacity": `Do que o Roberty é capaz?`,
  "common.robertyCapacityText": `Nossa coleção está repleta de funcionalidades RPA para que os robôs sejam capazes de executar rotinas complexas e, o mais importante, estamos em constante evoluçāo!`,
  "common.robertyClient": `Clientes utilizando o Roberty`,
  "common.robertyDealer": `Com nosso modelo de negócios, você não será um revendedor do Roberty e sim um parceiro e`,
  "common.robertyDescription": `Com o Roberty Designer, é possível criar robôs de forma rápida e simples. Ele foi projetado para que qualquer pessoa seja capaz de trabalhar com RPA.`,
  "common.robertyHistory": `O Roberty é uma ferramenta de RPA desenvolvida pela High Jump, que é uma empresa especializada em melhoria de processos de alto impacto com mais de 10 anos de experiência.`,
  "common.robertyHistoryMore": `Conheça nossa história`,
  "common.robertyNotExperience": `Você não precisa se preocupar em saber nenhuma linguagem de programação, apenas escolher qual ação você quer que o robô execute ou controle 🙂.`,
  "common.robertyPartner": `Partner 😃`,
  "common.robertyText": `A plataforma de automação robótica de processos (RPA) mais acessível e intuitiva do mercado. `,
  "common.runSeconds": `Tempo de Execução`,
  "common.schedule": `Agendar demonstração`,
  "common.safety": `Use primeiro pague depois`,
  "common.safetyText": `O primeiro pagamento só é feito 30 dias após a assinatura do contrato.`,
  "common.salesTeam": `contate vendas`,
  "common.SalesTeam": `Contate vendas`,
  "common.start": `Vamos começar!`,
  "common.startNow": `Começar agora`,
  "common.see": `Veja os`,
  "common.seeCosts": `Confira abaixo os custos para automatizar um processo com o Roberty e entre em contato com nossa equipe de vendas!`,
  "common.seconds": `seg`,
  "common.send": `Enviar`,
  "common.sendContact": `Enviar Contato`,
  "common.sendInfo": `Ao enviar essas informações, nós entendemos que você está de acordo com a nossa`,
  "common.sending": `Enviando`,
  "common.sendingContact": `Enviando Contato`,
  "common.services": `Serviços disponíveis para o seu negócio`,
  "common.serviceProvider": `Provedor de Serviços Tecnológicos`,
  "common.sign": `Assine o Roberty pela`,
  "common.smsSending": `Envios de SMS`,
  "common.solicitations": `Solicitações`,
  "common.structureUtilize": `Usando nossa estrutura de decisões e loops, o Roberty é capaz de automatizar qualquer regra de negócio da sua rotina 🙂.`,
  "common.successCases": `Empresas impactadas pelo Roberty`,
  "common.successContact": `Tudo certo! Em breve ligaremos pra você 🥳`,
  "common.support": `Suporte Exclusivo`,
  "common.supportConsulting": `Suporte e Consultoria técnica`,
  "common.supportDescription": `SLA reduzido e 24H`,
  "common.supportExclusive": `Suporte Exclusivo`,
  "common.supportPlan": `Suporte`,
  "common.survey": `Levantamento do processo a ser automatizado`,
  "common.talkOwners": `Fale com os donos da empresa e conheça melhor essas possibilidades. Não perca a oportunidade de ser sócio de uma empresa com potencial escalável.`,
  "common.talkSales": `Fale com nossa equipe de vendas agora mesmo!`,
  "common.technicalSupport": `Suporte técnico 24 horas por dia, 7 dias por semana`,
  "common.technologySupport": `Tecnologia e Suporte`,
  "common.toOrganizations": `Voltado para organizações que buscam mais recursos e desempenho!`,
  "common.uncomplicated": `descomplicada`,
  "common.unlimited": `Ilimitado`,
  "common.unlimitedBots": `Bot runners ilimitados`,
  "common.unlimitedLicenses": `Número ilimitado de licenças`,
  "common.upgrades": `Upgrades (nāo tem)`,
  "common.useRPA": `Utilização do RPA`,
  "common.useRoberty": `Por quê usar o Roberty (RPA)?`,
  "common.useRobertyReason": `Os principais motivos para você utilizar o Roberty Automation na sua empresa, e os benefícios que o RPA traz para a sua rotina.`,
  "common.reasonPartnerUse": `Como parceiro do Roberty, sua empresa passa a ofertar projetos de automação robótica de processos (RPA) sem precisar investir tempo e dinheiro em soluções próprias.`,
  "common.updates": `Atualizações periódicas no seu robô`,
  "common.values": `Valores de implantações dos robôs`,
  "common.wantCall": `Quero receber uma ligaçāo!`,
  "common.wantSchedule": `Clique aqui para agendar!`,
  "common.weContactYou": `nós entramos em contato com você.`,
  "common.whyRoberty": `Por quê usar o Roberty?`,
  "common.withRoberty": `Com o Roberty,`,
  "common.wppText": `Olá! Gostaria de conhecer mais sobre o Roberty e como ele pode ajudar nas rotinas da minha empresa :D`,
  "plans.forever": `Para sempre`,
  "plans.freeForever": `Gratuito para sempre`,
  "plans.onDemand": `Sob demanda`,
  "plans.notAvailable": `Não disponível neste plano`,
  "plans.daily": `Por dia`,
  "plans.monthly": `Por mês`,
  "plans.yearly": `Por ano`,
  "plans.enabled": `Habilitado durante assinatura`,
  "plans.feature.robots.title": `Robos ilimitados`,
  "plans.feature.robots.description": `Processos automatizados`,
  "plans.feature.devices.title": (amount?: number) => amount === 1 ? `1 Dispositivo` : `${amount || ""} Dispositivos`,
  "plans.feature.devices.description": `Instalações simultâneas do Roberty`,
  "plans.feature.runHours.title": (amount?: number) => amount === 1 ? `1 Hora de Execução` : `${amount || ""} Horas de Execução`,
  "plans.feature.runHours.description": `Tempo de execução dos robôs (somando todos os dispositivos)`,
  "plans.feature.scheduling.title": `Agendamento de robôs`,
  "plans.feature.scheduling.description": `Seus robôs serão executados no horário que você escolher`,
  "plans.feature.webhooks.title": `Webhooks`,
  "plans.feature.webhooks.description": `Integração com outros sistemas através da nossa API`,
  "plans.feature.messages.title": (amount?: number) => !amount ? `Mensagens` : `${amount || ""} Mensagens`,
  "plans.feature.messages.description": `E-mail, SMS e WhatsApp. Sem nenhuma configuração necessária 🥳`,
  "plans.feature.captchas.title": (amount?: number | undefined) => !amount ? `Captchas` : `${amount || ""} Captchas`,
  "plans.feature.captchas.description": `Requisições para resolver captchas (eu não sou um robô 🤖)`,
  "plans.feature.OCRIA.title": (amount?: number) => !amount ? `OCR + IA` : `${amount || ""} Leituras utilizando OCR + IA`,
  "plans.feature.OCRIA.description": `Leitura inteligente de documentos e imagens`,
  "plans.feature.basicTraining.title": `Treinamento Básico`,
  "plans.feature.basicTraining.description": `Acesse nossos vídeos introdutórios e aprenda a criar seus primeiros robôs`,
  "plans.feature.advancedTraining.title": `Treinamento Avançado`,
  "plans.feature.advancedTraining.description": `Acesse nossos vídeos avançados e aprenda a criar robôs mais complexos`,
  "plans.feature.dedicatedSupport.title": (hours?: number) => !hours ? `Suporte Dedicado` : `${hours || ""} Horas de Suporte Dedicado`,
  "plans.feature.dedicatedSupport.description": `Chamadas através do Google Meet com o nosso time de suporte`,
  "plans.feature.contactSpecialists.title": `Contato com Especialistas`,
  "plans.feature.contactSpecialists.description": `Nossos especialistas podem te ajudar a automatizar processos e criar os seus robôs`,
  "partnerList.partner": `Nossos parceiros`,
  "partnerList.partnerText": `Conheças algumas empresas de tecnologia que confiam em nosso trabalho.`,
	"home.header.mainTitle": `Plataforma de RPA para hiperautomação de processos`,
	"home.header.copy": `O Roberty é a ferramenta de automação robótica de processos RPA para os seus clientes, com implementação fácil e intuitiva.`,
	"home.header.cta": `Entrar em contato`,
	"home.header.clients.copy": `Potencialize a produtividade: economize milhares de horas do tempo de seus funcionários. Junte-se a eles hoje mesmo com o Roberty Automation.`,
	"home.intro.title": `Uma plataforma completa para automações`,
	"home.overview.title.designer": `Roberty Studio`,
	"home.overview.copy.designer": `Em um ambiente 100% web e em nuvem (AWS), o Roberty Automation oferece inúmeras possibilidades. É possível desenvolver seus robôs utilizando o Roberty Designer de forma simples, ágil e intuitiva (plataforma Low-Code e com uma biblioteca completa de ações para atender qualquer tipo de atividade). fazer toda orquestração e agendamento de trabalho dos robôs conforme necessidade da sua empresa em qualquer dia e horário da semana / mês  / ano, acompanhar o desempenho, gerir, monitorar, dar manutenção e sustentação, tudo isso em um ambiente seguro e criptografado ponta a ponta.`,
	"home.overview.title.collaboration": `Co-criação, Colaboração e Aprendizagem`,
	"home.overview.copy.collaboration": `O Roberty Automation é a primeira plataforma de RPA, que permite o desenvolvimento de robôs de forma colaborativa!! Isso mesmo, pessoas de qualquer lugar do mundo podem se conectar em um ambiente único e desenvolver robôs juntos, bem como resolverem conflitos em ambiente compartilhado. Incrível, não é? O Tempo de aprendizado também é um grande diferencial. Utilize o Roberty Academy para aprender sobre nossa plataforma e desenvolva seu primeiro robô  em menos 1 semana!`,
	"home.overview.cta.collaboration": `Alguma dúvida? Agende uma demonstração`,
	"home.overview.count.unit": `Milhão`,
	"home.testimonials.cta": `Torne-se agora um Parceiro`,
	"home.cases.title": `Cases de Sucesso`,
	"home.cases.copy": `O que nossos clientes estão dizendo sobre sua experiência com o Roberty?`,
	"home.cases.knowMore": `Conheça novos cases de sucesso e muito mais conteúdos sobre RPA em nosso blog.`,
	"home.cases.cta": `Ir para o Blog`,
	"home.cases.href": `Saiba Mais`,
	"home.finalAction.titleSpan": `Ainda com dúvidas`,
	"home.finalAction.title": `sobre do que o Roberty é capaz de fazer?`,
	"home.finalAction.copy": `Tivemos muito sucesso com todos os tipos de casos de uso comercial. Conte-nos sobre a sua empresa e suas oportunidades de negócio.`,
	"home.finalAction.cta": `Alguma dúvida? Entre em contato`
}

export default ptBR
