import React, { useEffect } from 'react'
import clsx from 'clsx'
import ReactGA from 'react-ga'
import { observer } from 'mobx-react-lite'

import { FiX } from 'react-icons/fi'

import * as styles from './contactModal.module.scss'
import { ContactModalContext } from './context'
import { ContactActions, ContactSales } from './contactActions'
import i18n from '../../i18n'

export interface ContactModalProps {
  context: typeof ContactModalContext
}

export const ContactModal: React.FC<ContactModalProps> = observer(
  ({ context }) => {
    useEffect(() => {
      if (context.visible) {
        ReactGA.pageview(`/get-started`)
      }
    }, [context.visible])

    useEffect(() => {
      const body = document.querySelector(`body`)
      if (body) {
        if (context.visible) {
          body.classList.add(`noOverflow`)
        } else {
          body.classList.remove(`noOverflow`)
        }
      }
    }, [context.visible])

    return (
      <div
        className={clsx(styles.contactModal, {
          [styles.visible]: context.visible,
          [styles.contactSales]: context.isContactSales,
        })}
      >
        <div
          className={styles.mask}
          onClick={() => {
            ContactModalContext.visible = false
            ContactModalContext.formCallVisible = false
            ContactModalContext.isContactSales = false
          }}
        ></div>

        <div className={styles.modalWrapper}>
          <div className={styles.modalContent}>
            <div className={styles.modalClose}>
              <button
                onClick={() => {
                  ContactModalContext.visible = false
                  ContactModalContext.formCallVisible = false
                  ContactModalContext.isContactSales = false
                }}
              >
                <FiX size={30} />
              </button>
            </div>
            <div className={styles.modalBody}>
              {!context.isContactSales && (
                <div className="container-fluid">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div
                      className={clsx(
                        styles.title,
                        'col-12 col-lg-5 col-xl-auto'
                      )}
                    >
                      <h1>
                        {i18n[`common.great`]}, <br />
                        <span>{i18n[`common.start`]}</span>
                      </h1>
                      <p>
                        {i18n[`common.optionsTalk`]}
                        <br />
                        {i18n[`common.prefer`]}
                        <strong>{i18n[`common.weContactYou`]}</strong>
                      </p>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-7 col-xl-6 pt-sm-4 pt-xl-0">
                      <ContactActions
                        isFormContact={ContactModalContext.formCallVisible}
                      />
                    </div>
                  </div>
                </div>
              )}
              {context.isContactSales && (
                <div className="container-fluid">
                  <div className="row">
                    <ContactSales />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
)
