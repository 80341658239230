import React, { useEffect, useCallback } from 'react'
import ReactGA from 'react-ga'
import '../styles/global.scss'

import { Header } from './header'
import { Footer } from './footer'
import { ToastAlert } from './toastAlert'
import { ContactModal, ContactModalContext } from './contactModal'
import { Loading, LoadingContext } from './loading'
import { api } from '../utils/api'

interface LayoutProps {
  isLargeFooter: boolean
  isHome: boolean
  location?: typeof window.location
  whiteHeader?: boolean
}

export const Layout: React.FC<LayoutProps> = ({
  isHome,
  isLargeFooter,
  location,
  whiteHeader,
  children,
}) => {
  useEffect(() => {
    location && ReactGA.pageview(location.pathname + location.search)
  }, [])

  const handleEventScheduled = useCallback(async (event: MessageEvent) => {
    if (event.data.event && event.data.event === `calendly.event_scheduled`) {
      try {
        const { payload } = event.data
        await api.post(`1/website/calendlyLead`, {
          eventURL: payload.invitee.uri,
        })
      } catch (error) {
        console.error(error)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener(`message`, handleEventScheduled)
    return () => {
      window.removeEventListener(`message`, handleEventScheduled)
    }
  }, [])

  return (
    <main style={{ position: 'relative' }}>
      <ToastAlert />
      <ContactModal context={ContactModalContext} />
      {!isHome && <Header isWhite={whiteHeader} />}
      {children}
      <Footer largeFooter={isLargeFooter} />
      <Loading context={LoadingContext} />
    </main>
  )
}
